import create from 'zustand';
import quiz from '../../content/quiz.json';

const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const useQuizStore = create(set => ({
  questions: quiz,
  questionsLength: quiz.length,
  activeQuestion: 0,
  currentAnswer: false,
  correctAnswers: 0,
  state: 'start', // start or quiz or quizInfo or result
  moveToNextQuestion: () =>
    set(state => ({ activeQuestion: state.activeQuestion + 1 })),
  increaseScore: () =>
    set(state => ({ correctAnswers: state.correctAnswers + 1 })),
  changeState: newState => set(state => ({ state: newState })),
  resetQuiz: () =>
    set(state => ({
      correctAnswers: 0,
      activeQuestion: 0,
      questions: shuffleArray(quiz),
    })),
  setCurrentAnswer: answer => set(state => ({ currentAnswer: answer })),
}));
