import React from 'react';
import { useQuizStore } from '../store/store';
import { colors } from '../styles/colors';
import styled from '@emotion/styled';
import { Box, Flex } from '@3pc/layout-components-react';
import { devices } from '../styles/breakpoints';

const Question = styled.section`
  width: 100%;
  padding: 20px;
  padding-right: 40px;

  @media ${devices.mobileMin} {
    padding-right: 20px;
  }
`;

const ContainerBox = styled.div`
  background: ${colors.white};
  position: relative;
  z-index: 0;
  border-top: 2px solid ${colors.blue};
  border-top-left-radius: 6px;
  margin-left: 20px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      ${colors.blue} 0%,
      ${colors.white} 100%
    );
    border-top-left-radius: 4px;
    z-index: -2;
  }

  &::after {
    content: '';
    position: absolute;
    left: 2px;
    top: 0px;
    width: calc(100% - 2px);
    height: 100%;
    background: ${colors.white};
    border-top-left-radius: 4px;
    z-index: -1;
  }

  @media ${devices.mobileMin} {
    margin: 0 15% 0 15%;
    padding-right: 0;
  }
`;

const Title = styled.h1`
  margin-bottom: 19px;
`;

const Text = styled.p`
  line-height: 1.313em;
`;

const Button = styled.button`
  padding: 21px 0 23px;
  border: 2px solid ${colors.blue};
  border-radius: 8px;
  background-color: transparent;
  position: relative;
  margin-top: 40px;
  max-width: 150px;
  flex-basis: 50%;
  transition: background 200ms ease-in-out;

  &:first-of-type {
    margin-right: 20px;
  }

  @media ${devices.mobileMin} {
    max-width: 360px;
  }

  @media ${devices.nonTouchable} {
    &:hover {
      background: ${colors['blue-rgba-one']};
    }
  }

  &:focus-visible {
    background: ${colors['blue-rgba-one']};
  }

  &:active {
    background: ${colors['blue-rgba-three']};
  }

  &:focus {
    outline: 0;
  }
`;

const QuizQuestion = ({ question, index }) => {
  const increaseScore = useQuizStore((state) => state.increaseScore);
  const changeState = useQuizStore((state) => state.changeState);
  const setCurrentAnswer = useQuizStore((state) => state.setCurrentAnswer);

  const handleAnswer = (answer) => {
    if (answer === question.answer) {
      increaseScore();
      setCurrentAnswer(true);
    } else {
      setCurrentAnswer(false);
    }

    changeState('quizInfo');
  };

  return (
    <Box mt={['calc(43px + 22px)', 'calc(43px + 61px)']} mb="calc(50px + 25px)">
      <ContainerBox>
        <Question aria-labelledby="header-title">
          <header>
            <Title id="header-title">Frage {index + 1}</Title>
          </header>
          <Text>{question.text}</Text>

          <Flex justifyContent={['space-around', 'space-between']}>
            <Button onClick={() => handleAnswer(0)}>Stimmt!</Button>
            <Button onClick={() => handleAnswer(1)}>Stimmt nicht!</Button>
          </Flex>
        </Question>
      </ContainerBox>
    </Box>
  );
};

export default QuizQuestion;
