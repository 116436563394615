import React from 'react';
import { useQuizStore } from '../store/store';
import { Box, Flex } from '@3pc/layout-components-react';
import { colors } from '../styles/colors';
import { devices } from '../styles/breakpoints';
import { headerHeight } from '../components/PageHeader';
import { footerHeight } from '../components/Footer';
import { maxWidthVals } from '../styles/container';
import styled from '@emotion/styled';
import ArrowRight from '../../static/icons/arrow-right.svg';
import ResponsiveImage from '../base/ResponsiveImage';

const PageTitle = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: ${colors.white};
  font-size: 1.25em;
  line-height: 1.25em;
  width: 100%;
  padding: 0 20px;

  @media ${devices.mobileMin} {
    font-size: 1.875em;
    line-height: 2.25em;
  }
`;

const ForwardButton = styled.button`
  padding: 21px 20px 23px;
  border: 2px solid ${colors.blue};
  border-radius: 8px;
  background: transparent;
  position: relative;
  margin-top: 30px;
  width: 100%;
  text-align: left;
  flex-basis: 50%;
  transition: background 200ms ease-in-out;

  @media ${devices.mobileMin} {
    margin-top: 0;
    margin-left: 25px;
    padding: 33px 20px 33px;
  }

  & svg {
    position: absolute;
    top: 18px;
    right: 20px;

    @media ${devices.mobileMin} {
      top: 28px;
    }
  }

  @media ${devices.nonTouchable} {
    &:hover {
      background: ${colors['blue-rgba-one']};
    }
  }

  &:focus-visible {
    background: ${colors['blue-rgba-one']};
  }

  &:active {
    background: ${colors['blue-rgba-three']};
  }

  &:focus {
    outline: 0;
  }
`;

const QuizStart = ({
  headerImageDefault,
  headerImageMobile,
  headerImageAltTag,
}) => {
  const changeState = useQuizStore((state) => state.changeState);

  return (
    <Box
      as="section"
      mt={headerHeight.headerMobile}
      mb={footerHeight.footerMobile}
    >
      <Box as="header" position="relative" aria-labelledby="header-title">
        <ResponsiveImage
          defaultImage={headerImageDefault}
          mobileImage={headerImageMobile}
          altTag={headerImageAltTag}
        />
        <PageTitle id="header-title">
          Das Quiz zur Hessischen Verfassung
        </PageTitle>
      </Box>
      <Box padding="20px 20px 31px" mb={footerHeight.footerMobile}>
        <Flex
          flexDirection={['column', 'row']}
          maxWidth={maxWidthVals.uiMaxWidth}
          mx="auto"
          lineHeight="21px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flexBasis="50%">
            <p>
              Wann entstand die Hessische Verfassung und wie viele
              Unterschriften benötigt eigentlich eine Petition um in den Landtag
              zu kommen? Testen Sie ihr Wissen bei unserem Quiz rund um die
              Verfassung.
            </p>
          </Box>

          <ForwardButton onClick={() => changeState('quiz')}>
            <span>Quiz anfangen!</span>
            <ArrowRight width="11" height="20" aria-hidden="true" />
          </ForwardButton>
        </Flex>
      </Box>
    </Box>
  );
};

export default QuizStart;
