import React from 'react';
import { useQuizStore } from '../store/store';
import RightAnswer from '../../static/icons/richtig.svg';
import WrongAnswer from '../../static/icons/falsch.svg';
import { Box, Flex } from '@3pc/layout-components-react';
import { colors } from '../styles/colors';
import styled from '@emotion/styled';
import { devices } from '../styles/breakpoints';
import ArrowRightBold from '../../static/icons/arrow-right-bold.svg';
import ArrowRight from '../../static/icons/arrow-right.svg';
import { sizes } from '../styles/breakpoints';
import { maxWidthVals } from '../styles/container';

const Solution = styled.section`
  width: 100%;
  padding: 20px;
  padding-right: 40px;
  line-height: 1.313em;
`;

const ContainerBox = styled.div`
  background: ${colors.white};
  z-index: 0;
  position: relative;
  border-top: 2px solid ${colors.blue};
  border-top-left-radius: 6px;
  margin-left: 20px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    max-width: calc(${maxWidthVals.pageMaxWidth} / 2 + 50%);
    height: 100%;
    background: linear-gradient(
      180deg,
      ${colors.blue} 0%,
      ${colors.white} 100%
    );
    border-top-left-radius: 4px;
    z-index: -2;
  }

  &::after {
    content: '';
    position: absolute;
    left: 2px;
    top: 0px;
    width: calc(100% - 2px);
    height: 100%;
    background: ${colors.white};
    border-top-left-radius: 4px;
    z-index: -1;
  }

  @media ${devices.mobileMin} {
    margin: 0 15% 0 15%;
    padding-right: 0;
  }
`;

const Title = styled.h1`
  margin-top: 17px;
  margin-bottom: 15px;

  @media ${devices.mobileMin} {
    margin: 3px auto 29px 29px;
  }
`;

const Line = styled.div`
  border-bottom: 2px solid ${colors.blue};
  width: 100%;
  margin: 20px auto;
`;

const Answer = styled.p`
  font-size: 1.25em;
  font-weight: bold;
  margin: 9px 0 21px;
  line-height: 1.313em;

  @media ${devices.mobileMin} {
    font-size: 1.875em;
    line-height: 2.25em;
    margin: 7px 0 13px;
  }
`;

const Button = styled.button`
  padding: 14px 10px;
  border: 2px solid ${colors.blue};
  border-radius: 8px;
  background-color: transparent;
  position: relative;
  margin-top: 40px;
  flex-basis: 50%;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background 200ms ease-in-out;

  @media ${devices.mobileMin} {
    padding: 33px 21px;
  }

  @media ${devices.nonTouchable} {
    &:hover {
      background: ${colors['blue-rgba-one']};
    }
  }

  &:focus-visible {
    background: ${colors['blue-rgba-one']};
  }

  &:active {
    background: ${colors['blue-rgba-three']};
  }

  &:focus {
    outline: 0;
  }
`;

const QuizQuestionInfo = ({ question, index }) => {
  const moveToNextQuestion = useQuizStore((state) => state.moveToNextQuestion);
  const questionsLength = useQuizStore((state) => state.questionsLength);
  const changeState = useQuizStore((state) => state.changeState);
  const currentAnswer = useQuizStore((state) => state.currentAnswer);
  const answerIsRight = currentAnswer;

  const handleClick = () => {
    if (index + 1 !== questionsLength) {
      moveToNextQuestion();
      changeState('quiz');
    } else {
      changeState('result');
    }
  };

  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  return (
    <Box mt={['calc(43px + 22px)', 'calc(43px + 61px)']} mb="calc(50px + 25px)">
      <ContainerBox>
        <Solution aria-labelledby="header-title">
          <Box>
            <Flex flexDirection={['column', 'row']}>
              <div aria-hidden="true">
                {answerIsRight ? (
                  <RightAnswer width="48px" height="48px" />
                ) : (
                  <WrongAnswer width="48px" height="48px" />
                )}
              </div>
              <header id="header-title">
                <Title>
                  Ihre Antwort ist {answerIsRight ? 'richtig!' : 'falsch...'}
                </Title>
              </header>
            </Flex>
            <p>Frage: {question.text}</p>
            <Line />
            <h2>Antwort:</h2>
            <Answer>
              {question.answer === 0 ? 'Stimmt!' : 'Stimmt nicht!'}
            </Answer>
            <h3 className="sr-only">Erklärung:</h3>
            <div dangerouslySetInnerHTML={{ __html: question.explanation }} />
          </Box>
          <Flex>
            <Button onClick={() => handleClick()}>
              {index + 1 !== questionsLength ? 'Nächste Frage' : 'zum Ergebnis'}
              {width > sizes.mobile ? (
                <ArrowRight width="11" height="20" aria-hidden="true" />
              ) : (
                <ArrowRightBold width="6" height="10" aria-hidden="true" />
              )}
            </Button>
          </Flex>
        </Solution>
      </ContainerBox>
    </Box>
  );
};

export default QuizQuestionInfo;
