import React from 'react';
import { useQuizStore } from '../store/store';
import { headerHeight } from '../components/PageHeader';
import { footerHeight } from '../components/Footer';
import { Box } from '@3pc/layout-components-react';
import { colors } from '../styles/colors';
import styled from '@emotion/styled';
import { devices } from '../styles/breakpoints';
import ArrowRight from '../../static/icons/arrow-right.svg';

const Title = styled.h1`
  font-size: 1.25em;
  line-height: 1.25em;
  margin-bottom: 21px;
`;

const CorrectAnswers = styled.span`
  font-size: 4.5em;
  line-height: 1.5em;
  color: ${colors.blue};
  display: block;
`;

const ForwardButton = styled.button`
  padding: 21px 20px 23px;
  border: 2px solid ${colors.blue};
  border-radius: 8px;
  background: transparent;
  position: relative;
  margin-top: 30px;
  width: 100%;
  text-align: left;
  flex-basis: 50%;
  transition: background 200ms ease-in-out;

  @media ${devices.mobileMin} {
    padding: 33px 20px 33px;
  }

  & svg {
    position: absolute;
    top: 18px;
    right: 20px;

    @media ${devices.mobileMin} {
      top: 28px;
    }
  }

  @media ${devices.nonTouchable} {
    &:hover {
      background: ${colors['blue-rgba-one']};
    }
  }

  &:focus-visible {
    background: ${colors['blue-rgba-one']};
  }

  &:active {
    background: ${colors['blue-rgba-three']};
  }

  &:focus {
    outline: 0;
  }
`;

const QuizResult = () => {
  const correctAnswers = useQuizStore((state) => state.correctAnswers);
  const questionsLength = useQuizStore((state) => state.questionsLength);
  const changeState = useQuizStore((state) => state.changeState);
  const resetQuiz = useQuizStore((state) => state.resetQuiz);

  const restartQuiz = () => {
    resetQuiz();
    changeState('quiz');
  };

  return (
    <Box
      as="section"
      mt={headerHeight.headerMobile}
      mb={footerHeight.footerMobile}
      ml="auto"
      mr="auto"
      pt="52px"
      pb={['0', '52px']}
      width="280px"
      aria-labelledby="header-title"
    >
      <Box textAlign="center">
        <header>
          <Title id="header-title">Vielen Dank für Ihre Teilnahme!</Title>
        </header>
        <p>
          Sie haben
          <CorrectAnswers>{correctAnswers}</CorrectAnswers>
          von Fragen {questionsLength} richtig beantwortet.
        </p>
      </Box>

      <ForwardButton onClick={() => restartQuiz()}>
        <span>Erneut versuchen</span>
        <ArrowRight width="11" height="20" aria-hidden="true" />
      </ForwardButton>
    </Box>
  );
};

export default QuizResult;
