import React from 'react';
import Layout from '../components/Layout';
import QuizResult from '../components/QuizResult';
import QuizQuestion from '../components/QuizQuestion';
import QuizStart from '../components/QuizStart';
import QuizQuestionInfo from '../components/QuizQuestionInfo';
import { useQuizStore } from '../store/store';
import { graphql } from 'gatsby';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';

export const query = graphql`
  query {
    image: file(relativePath: { eq: "12.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          aspectRatio: 3.10303
          transformOptions: { grayscale: true, cropFocus: NORTH }
          placeholder: BLURRED
        )
      }
    }
    mobileImage: file(relativePath: { eq: "12.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          aspectRatio: 1.77778
          transformOptions: { grayscale: true, cropFocus: NORTH }
          placeholder: BLURRED
        )
      }
    }
  }
`;

const Quiz = ({ data }) => {
  const questions = useQuizStore((state) => state.questions);
  const activeQuestion = useQuizStore((state) => state.activeQuestion);
  const state = useQuizStore((state) => state.state);

  const defaultImage = data.image.childImageSharp.gatsbyImageData;
  const mobileImage = data.mobileImage.childImageSharp.gatsbyImageData;
  const altTag =
    'Farbfotografie von Schülern und Schülerinnen in einem Plenarsaal während eines Workshops zur Verfassungsänderung im Jahr 2018';

  return (
    <>
      <PageHeader headerTitle="Quiz" pageType={'list-page'} />
      <Layout>
        {state === 'start' ? (
          <QuizStart
            headerImageDefault={defaultImage}
            headerImageMobile={mobileImage}
            headerImageAltTag={altTag}
          />
        ) : state === 'quiz' ? (
          <QuizQuestion
            question={questions[activeQuestion]}
            index={activeQuestion}
          />
        ) : state === 'quizInfo' ? (
          <QuizQuestionInfo
            question={questions[activeQuestion]}
            index={activeQuestion}
          />
        ) : state === 'result' ? (
          <QuizResult />
        ) : (
          ''
        )}
      </Layout>
      <Footer />
    </>
  );
};
export default Quiz;
